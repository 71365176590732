<template src="./initiateeft.html"></template>
<style scoped src="./initiateeft.css"></style>
<script>
import getRecipients from '@/components/modal/getRecipient/recipient.vue'
import accountService from '@/services/AccountService.vue'
import transactionService from '@/services/TransactionService.vue'
import { required ,minValue} from 'vuelidate/lib/validators'
import applicationConstants from '@/constants/AppConstants.vue'
import Loading from 'vue-loading-overlay'
import xmlUtils from '@/utils/utils.vue'
import bankService from '@/services/BankService.vue'
import modal from '@/components/modal/notification/modal'
import {mapActions, mapGetters} from 'vuex'

export default {
    components : {
        Loading,getRecipients, modal
    },
    data () {
        return {
            pcmBalance : '',
            sourceCurrency : '',
            bankAccount : null,
            tPurpose : null,
            requestedAmount : '',
            promoCode : '',
            remittanceOption : {},
            loaderVisible : false,
            exchangeRate : '',
            qicSendFee : '',
            sourceAmount :'',
            destAmount :'',
            bankAccounts : [],
            errorMessages : applicationConstants.formsMessages,
            recipientName: '',
            showGetRecipients : true,
            accountWhitleListed: false,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            senderAccountStatus: null // for checking sender is signedup or not
        }
    },
    methods : {
        ...mapActions(['fetchRemittanceOption']),
        async selectRemittanceOption(gateWayId) {
            if(!this.getRemittanceOptions2) {
                await this.fetchRemittanceOption()
            }
            this.remittanceOption = this.getRemittanceOptions2.find(el => el.RemittanceOptionId === gateWayId)        },
        responseMapping(data) {
            if(data)  {
                data=Array.isArray(data) ? data : [data]
                data.map(bankAccounts =>{
                    Object.keys(bankAccounts).map(key=> bankAccounts[key] = bankAccounts[key]?._text)
                })
            }else{
                data = []
            }
            return data
        },
        async initiateEFT() {
            try {
                this.bankAccount = this.bankAccount === 'null' ? null : this.bankAccount
                this.tPurpose = this.tPurpose === 'null' ? null : this.tPurpose
                this.$v.$touch()
                if(this.$v.$invalid) {
                    return
                }
                if(!this.promoCode.includes('-') && this.promoCode.length > 0) {
                    this.showAlert(null, 'Invalid promo code format')
                    return
                }
                if(this.senderAccountStatus === '4') {
                    this.showAlert(null, 'Customer account status is Signedup. Please complete the profile before initiate E-Transfer.')
                    return
                }
                let code = null
                let issue = null
                if(this.promoCode !== '') {
                    let promoc = this.promoCode.split('-')
                    code = promoc[0]
                    issue = promoc[1]
                }
                this.loaderVisible = true
                await transactionService.methods.InitiateEFT({
                    TransactionTotal : this.sourceAmount,
                    BankAccountId : this.bankAccount,
                    CustomerEmail : this.senderEmail,
                    PromoCodeData : {
                        Amount : this.requestedAmount,
                        RemittanceGatewayId : this.recipientData.RemittanceGatewayId,
                        Code : code,
                        Issue : issue
                    },
                    SendMoneyRequest : {
                        Purpose : this.tPurpose,
                        OriginCurrency:this.sourceCurrency,
                        RecipientId : this.recipientData.Id,
                        XferAmount : this.requestedAmount,
                        CustomerEmail : this.senderEmail,
                        RequestedDestinationAmount : this.destAmount
                    }
                })
                .then( () => {
                    this.loaderVisible = false
                    this.showAlert(1, 'EFT Initiated Successfully')
                })
                .catch(err => {
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)
                })
                setTimeout(() => { this.goBack()},3000)
            } catch (error) {
               this.loaderVisible=false
            }
        },
         async calculateTransationFee () {
            try {
                this.$v.requestedAmount.$touch()
                if(this.$v.requestedAmount.$invalid) {
                    this.exchangeRate = ''
                    this.qicSendFee = ''
                    this.sourceAmount = ''
                    this.destAmount = ''
                    return
                }
                if(!this.promoCode.includes('-') && this.promoCode.length > 0) {
                    this.showAlert(null, 'Invalid Code format')
                    return
                }
                if (+this.requestedAmount < 50 && !this.accountWhitleListed) {
                    this.requestedAmount = ''
                    this.showAlert(null, 'We require $50 CAD as the minimum transfer amount for security purposes. Please enter an amount of $50 or above.')
                    return
                }
                this.loaderVisible = true
                let res= await xmlUtils.methods.calculateTransationFee({
                    DestCountry : this.recipientData.Country,
                    DestCurrency: this.remittanceOption.Currency,
                    OriginCountry : 'Canada',
                    OriginCurrency : 'CAD',
                    RemittanceGatewayId : this.recipientData.RemittanceGatewayId,
                    SendAmountOrigin: this.requestedAmount
                },this.promoCode,this.senderEmail)
                this.loaderVisible = false
                if(!res.Error || res.ErrorCode === '2000') {
                    if(res.ErrorCode === '2000') {
                        this.alertObject = res.Error
                        this.$bvModal.show(this.$refs.childModal.id)
                    }
                    this.exchangeRate = res.ExchangeRate
                    this.qicSendFee = res.Fee
                    this.sourceAmount = res.SourceAmount
                    this.destAmount = res.DestAmount
                }else {
                    this.alertObject = res.Error
                    this.$bvModal.show(this.$refs.childModal.id)
                }
            } catch (error) {
                //
            }
        },
        async getRecipientsData(recipient) {
            this.clearFields()
            this.showGetRecipients = false
            this.senderEmail = recipient.email
            this.recipientData = recipient
            this.loaderVisible = true
            bankService.methods.GetBankDetails(this.senderEmail)
            .then(res =>{
                this.bankAccounts = this.responseMapping(res.csGetBankAccountsResponse.BankAccountsItems?.BankAccountsItem)
            })
            .catch(err => {
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)
            })
            accountService.methods.GetAccountBalance(this.senderEmail)
            .then(res => {
                this.pcmBalance = res.csAccountBalanceResponse.Balance._text ? res.csAccountBalanceResponse.Balance._text : ''
                this.sourceCurrency = res.csAccountBalanceResponse.Currency._text ? res.csAccountBalanceResponse.Currency._text : ''
            })
            .catch(err => {
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)
            })
            accountService.methods.GetAccountDetails(this.senderEmail)
            .then( res => {
                this.accountWhitleListed = (res.csGetUserProfileResponse.WhitelistedForLimits._text).toLowerCase() === 'true'
                this.senderAccountStatus = res.csGetUserProfileResponse.AccountStatus._text ? res.csGetUserProfileResponse.AccountStatus._text : null
            }).catch(err=> {
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)
            })
            this.recipientName = this.recipientData.FirstName + ' ' + this.recipientData.LastName
            await this.selectRemittanceOption(this.recipientData.RemittanceGatewayId)
            this.loaderVisible = false
        },
        goBack() {
           this.showGetRecipients = true
        },
        clearFields() {
            this.bankAccount = null,
            this.requestedAmount = ''
            this.tPurpose = null
            this.promoCode = ''
            this.exchangeRate = ''
            this.qicSendFee = ''
            this.sourceAmount = ''
            this.destAmount = ''
            this.remittanceOption = {}
            this.senderAccountStatus = null
            this.$v.$reset()
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        }
    },
    validations : {
        bankAccount : { required },
        requestedAmount : { required ,minValue : minValue(0.001)},
        tPurpose : { required }
    },
    computed: {
        ...mapGetters(['getRemittanceOptions2'])
    }
}
</script>
